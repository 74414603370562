import React from 'react';
import { Link } from 'gatsby'
import { kebabCase } from 'lodash';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

const Tag = ({ tags }) => (
  <Wrapper>
    <TagList>
      <StyledFontAwesomeIcon icon={faTags} />
      {tags.map(tag => (
        <ListItem key={tag.fieldValue}>
          <StyledLink to={`/tags/${kebabCase(tag)}`}>
            {tag}
          </StyledLink>
        </ListItem>
      ))}
    </TagList>
  </Wrapper>
)

const Wrapper = styled.div`
  display: inline-block;
`

const TagList = styled.ul`
  font-size: .7rem;
  margin: 10px 0 0;
  list-style: none;
  display: inline-block;
  margin-left: .5rem;
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px 7px;
  background-color: #fff;

  &:last-child {
    margin-right: 0;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: #4078c0;
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: .7rem;
`

export default Tag;
